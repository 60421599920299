.rejectBlock {
    .rejectInfo {
        background-color: var(--light_black);
        border-radius: 10px;
        padding: 15px 10px;

        display: flex;
        flex-direction: column;
        gap: 10px;

        p {
            font-size: 1rem;
            color: #e94804;
            font-weight: 600;
        }

        small {
            font-size: 0.875rem;
            white-space: pre-wrap;
            line-height: 20px;
        }

        div {
            margin: 0 auto;
            height: 47px;
        }
    }
}
