.base-mini-loader {
	animation: 1s miniLoader infinite linear;
}

@keyframes miniLoader {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}
